body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f9f9f9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.page-layout {
  max-width: 1120px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2em;
}

.page-grid {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(4, minmax(250px, 1fr));
}

.page-header {
  font-size: 60px;
  font-weight: 800;
  margin-bottom: 0.5em;
}

.container {
  display: flex;
  flex-direction: column;
}

/* .notes-list {
  display: grid;

  grid-gap: 1rem;
  grid-template-columns: repeat(1, minmax(250px, 1fr));
  
} */

.notes-list {
  display: flex;
  flex-direction: column;
}

.note {
  background-color: #fef970;
  border-color: red;
  height: 100px;
  width: 82%;
  margin-bottom: 1rem;
}

.new-note {
  background-color: #cffe70;
}

.card {
  border-radius: 20px;
  padding: 1rem;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 7px 7px rgba(0, 0, 0, 1);
  border: 2px solid black;
}

textarea {
  border: none;
  resize: none;
  background-color: #cffe70;
  font-size: 16px;
}

textarea:focus {
  outline: none;
}

textarea::placeholder {
  color: black;
}

select {
  border: none;
  width: fit-content;
  background-color: #cffe70;
}

select:focus {
  outline: none;
}

.search {
  display: flex;
  align-items: center;
  background-color: white;
  border: 2px solid black;
  border-radius: 0.8rem;
  padding: 0.4rem;
  margin-bottom: 2em;
  box-shadow: 7px 7px rgba(0, 0, 0, 1);
}

.search input {
  background-color: white;
  border: none;
  margin-left: 10px;
  color: #000000;
  width: 100%;
}

.search span {
  margin-left: 10px;
}

.date {
  margin-bottom: 1.5rem;
  border-top: none;
  border-left: none;
  border-right: none;
  background-color: #cffe70;
}

input:focus {
  outline: none;
}

.time-estimate {
  width: 2.5rem;
  margin-bottom: 0.6rem;
  border-top: none;
  border-left: none;
  border-right: none;
  background-color: #cffe70;
}

.priority {
  margin-bottom: 0.6rem;
}

.note-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.note-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

button {
  border-radius: 0.4rem;
  background-color: white;
  box-shadow: 3px 3px rgba(0, 0, 0, 1);
}

button:hover {
  cursor: pointer;
}

.icon {
  cursor: pointer;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  background-color: #a970ff;
  box-shadow: 2px 2px rgba(0, 0, 0, 1);
  margin-left: 8px;
}

.header {
  font-weight: 800;
  margin-bottom: 5px;
  font-size: 28px;
}

.done {
  background-color: #fbd4a6;
}

.done .icon {
  background-color: #a5e1fa;
  cursor: auto;
}

.task-page {
  background-color: rgb(183, 246, 225);
  background-image: url("data:image/svg+xml,<svg id='patternId' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><defs><pattern id='a' patternUnits='userSpaceOnUse' width='20' height='20' patternTransform='scale(2) rotate(0)'><rect x='0' y='0' width='100%' height='100%' fill='hsla(0, 0%, 100%, 1)'/><path d='M 10,-2.55e-7 V 20 Z M -1.1677362e-8,10 H 20 Z'  stroke-width='1' stroke='hsla(145,59.4%,59.4%,1)' fill='none'/></pattern></defs><rect width='800%' height='800%' transform='translate(0,0)' fill='url(%23a)'/></svg>");
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.task .sticker{
  background-image: url("../public/sticker-afro.png");
  background-repeat: no-repeat;
  background-size: contain;
  height: 200px;
  width: 200px;
  position: absolute;
  z-index: 9;
  margin-left: 500px;
  margin-top: 200px;
}

.timer .sticker{
  background-image: url("../public/sticker-dino.png");
  background-repeat: no-repeat;
  background-size: contain;
  height: 200px;
  width: 200px;
  position: absolute;
  z-index: 9;
  margin-right: 250px;
  margin-bottom: 250px;
}

.timer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #FFD7B8;
  background-image: url("data:image/svg+xml,<svg id='patternId' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><defs><pattern id='a' patternUnits='userSpaceOnUse' width='40' height='40' patternTransform='scale(1) rotate(30)'><rect x='0' y='0' width='100%' height='100%' fill='hsla(0,0%,100%,1)'/><path d='M11 6a5 5 0 01-5 5 5 5 0 01-5-5 5 5 0 015-5 5 5 0 015 5'  stroke-width='1' stroke='none' fill='hsla(43, 100%, 50%, 1)'/></pattern></defs><rect width='800%' height='800%' transform='translate(0,0)' fill='url(%23a)'/></svg>");
  width: 300px;
  height: 300px;
  border-radius: 20px;
  border: 2px solid black;
  box-shadow: 7px 7px rgba(0, 0, 0, 1);
}

.timer span{
  font-size: 36px;
}

.time-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid black;
  background-color: white;
  
}

.time-box span {
  font-size: 56px;
  font-weight: 800;
  min-width: 80px;
  display: flex;
  justify-content: center;

}

.btn-timer{
  font-size: 22px;
  margin-left: 10px;
  padding: 6px;
  background-color: #FFD7B8;
  min-width: 120px;
}

.btn-timer:hover{
  background-color: #B3835D;
}

.timer-container{
  display: flex;
  flex-direction: column;
  width: 30%;
  float: left;
  margin-left: 5em;
}

.task-container {
  max-width: 1120px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 12em;
  width: 70%;
  /* float: right; */
  margin-right: 15em;
}

.timer-buttons{
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.timer-buttons .icon{
  width: 40px;
  height: 40px;
}

.text-container{
  margin: 5em;
  position: relative;
}

.task{
  background-color: white;
  padding-top: 0;
  padding-left: 2em;
  padding-bottom: 2em;
  padding-right: 2em;
  /* min-height: 20em; */
  flex: auto;
}

.task h1{
  font-size: 60px;
  /* position:absolute;
  top: 0;
  left: 0; */
}

.task .top-section{
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* position: relative; */
}

.task span{
  /* position: absolute; */
  /* margin-top: 5px; */
  margin-right: 10px;
  font-size: 32px;
}

.cancel{
  background-color: rgb(255, 100, 128);
  margin-right: .5em;
}

.scrollable{
  height: 60vh;
  overflow: auto;
}



